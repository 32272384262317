
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import store from '@/store';
import { StorageServices } from '@/services/StorageServices';
import { LoginServices } from '@/services/LoginServices';
import { ConversationRoomClient, NotificationClient } from '@/services/Services';
import { MagiaClient } from '@/services/Services';

@Options({})
export default class MenuLayout extends Vue {
    
    activeTab: string = "";
    communitiesLink = "";

    created() {   
        // if(!store.state.user || !store.state.user.identifier){
        //     LoginServices.logout();
        //     return;
        // } // a che serviva? se chiama il troilo ci parla gian.

        if(store.state.query)
            this.checkActiveTab(store.state.query);
        else
            this.checkActiveTab(this.$route.fullPath);

        // var firstDiaryAccessDone = StorageServices.getFirstDiaryAccessDone();
        // if(firstDiaryAccessDone)
        //     this.communitiesLink = "/communities";
        // else
        //     this.communitiesLink = "/communities-intro";
        this.communitiesLink = "/communities-feed";
    }

    @Watch("$route.fullPath", { deep: true })
    updateActiveTab() {
        this.checkActiveTab(this.$route.fullPath);
    }

    checkActiveTab(url: string) {
        if(url) {
            if(url.indexOf("profile") != -1)
                this.activeTab = "profile";
            else if(url.indexOf("communities") != -1)
                this.activeTab = "communities";
            else 
                this.activeTab = url.substring(1);
        }
    }

    get hasNewChatMessages(){
        return store.state.newChatMessages.length > 0;
    }

    get hasNewInvitation(){
        return store.state.newInvitationCount > 0;
    }

}

